import { IHttpClient } from '@wix/yoshi-flow-editor';
import { ORDER_API_PATH, PRODUCT_API_PATH } from './constants';
import { getBusinessDataLink, getPremiumStatusLink } from './links';
import type {
  CreateProductRequest,
  CreateProductResponse,
  GetProductResponse,
  UpdateProductRequest,
  CreateOrderRequest,
} from '@wix/ambassador-payment-paybutton-web/http';
import type { PropertiesReadResponse } from '@wix/ambassador-site-properties-public-web/http';

export async function makeRequestForOrder(
  httpClient: IHttpClient,
  instance: string,
  productId: string,
) {
  const data: CreateOrderRequest = { productId };
  return httpClient
    .post(ORDER_API_PATH, data, {
      headers: { Authorization: instance },
    })
    .then((resp) => resp.data as { orderId: string });
}

export const createProduct = (
  httpClient: IHttpClient,
  instance: string,
  data: CreateProductRequest,
) => {
  return httpClient
    .post(PRODUCT_API_PATH, data, { headers: { Authorization: instance } })
    .then((resp) => resp.data as CreateProductResponse);
};

export const getProduct = (
  httpClient: IHttpClient,
  instance: string,
  productId: string,
) => {
  return httpClient
    .get(`${PRODUCT_API_PATH}/${productId}`, {
      headers: { Authorization: instance },
    })
    .then((resp) => resp.data as GetProductResponse);
};

export const updateProduct = async (
  httpClient: IHttpClient,
  instance: string,
  data: UpdateProductRequest,
  productId: string,
) => {
  await httpClient.put(`${PRODUCT_API_PATH}/${productId}`, data, {
    headers: { Authorization: instance },
  });
};

export const updateProductCurrencyFromSettings = async (
  httpClient: IHttpClient,
  productId: string,
  instance: string,
) => {
  const [productData, { paymentCurrency: currency }] = await Promise.all([
    getProduct(httpClient, instance, productId),
    getBusinessData(httpClient, instance),
  ]);
  // NOTE: fallback for rare cases when currency is not set (PAY-23787)
  productData.product!.currency = currency || 'USD';
  await updateProduct(
    httpClient,
    instance,
    { product: productData.product!, productId },
    productId,
  );
};

export const getBusinessData = (httpClient: IHttpClient, instance: string) => {
  return httpClient
    .get(getBusinessDataLink(), { headers: { Authorization: instance } })
    .then((resp) => (resp.data as PropertiesReadResponse).properties!);
};

export const getPremiumStatus = (
  httpClient: IHttpClient,
  instance: string,
): Promise<boolean> => {
  return httpClient
    .get(getPremiumStatusLink(), { headers: { Authorization: instance } })
    .then((resp) => (resp as any).data.canAcceptOnlinePayments)
    .catch(() => true);
};
